<script setup>

import PrimaryButton from '@/Components/PrimaryButton.vue';
import SecondaryButton from "@/Components/SecondaryButton.vue";

import {getCurrentInstance, ref } from "vue";

import {useStore} from "vuex";
const store = useStore();

// Control ui
const show = ref(false);

const appInstance = getCurrentInstance();
const emitter = appInstance.appContext.config.globalProperties.emitter;

emitter.on('cartItemDeleteButtonClicked', (uuid) => {
	show.value = uuid === props.item_uuid;
});

emitter.on('cartItemHideDeleteConfirmation', () => {
	show.value = false;
});

const props = defineProps({
	item_uuid : {
		type :[String, null],
		required : true
	}
});

const deleteItem = () => {
	store.dispatch('cart/deleteItem', props.item_uuid)

	// Hide the delete confirmation
	emitter.emit('cartItemHideDeleteConfirmation');
}

const cancelDelete = () => {
	emitter.emit('cartItemHideDeleteConfirmation');
}

</script>

<template>
	<div class="delete_confirmation d-flex flex-column justify-content-center align-items-center" v-if="show">

			<span class="confirmation_title mb-3" >
				{{__('remove_product')}}
			</span>

		<div class="w-100 px-2 d-flex justify-content-center button_holder">

			<SecondaryButton class="ms-4 me-1 font-semibold flex-fill" @click="deleteItem">
				{{__('yes')}}
			</SecondaryButton>

			<PrimaryButton class="ms-1 me-4 font-semibold flex-fill" @click="cancelDelete">
				{{__('no')}}
			</PrimaryButton>

		</div>

	</div>
</template>